<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="units.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="units.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('units.fields.code') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.code"
                    :placeholder="i18n('units.fields.code')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('units.fields.project') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <v-select
                    @option:selected="selectedProject"
                    :options="projectOptions || []"
                    :label="labelLocalized"
                    v-model="model.project"
                    class="mt-5 select-center"
                    style="width: 250px"
                    title="Select item"
                    :loading="projectsLoading"
                  >
                    <template #no-options>
                      <div>
                        {{
                          isRTL
                            ? 'لا يوجد مشاريع اضف مشروع اولا'
                            : 'No projects create one first'
                        }}
                      </div>
                    </template>
                    <template #spinner="{ loading }">
                      <div v-if="loading" class="vs__spinner"></div> </template
                  ></v-select>
                </div>
              </div>
              <div
                class="grid grid-cols-12 gap-x-5 mt-5 items-center"
                v-show="selectedProjectId && selectedProjectId.length > 0"
              >
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('units.fields.unitTemplate') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <v-select
                    :options="templateOptions || []"
                    :label="labelLocalized"
                    v-model="model.unitTemplate"
                    class="mt-5 select-center"
                    style="width: 250px"
                    title="Select item"
                    :loading="templatesLoading"
                  >
                    <template #no-options>
                      <div>
                        {{
                          isRTL
                            ? 'لا يوجد نماذج اضف نموذج اولا'
                            : 'No template create one first'
                        }}
                      </div>
                    </template>
                    <template #spinner="{ loading }">
                      <div v-if="loading" class="vs__spinner"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      templateOptions: null,
      projectOptions: null,
      selectedProjectId: null,
      model: null,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'unit/form/record',
      findLoading: 'unit/form/findLoading',
      saveLoading: 'unit/form/saveLoading',
      projects: 'project/list/rows',
      templates: 'template/list/rows',
      projectsLoading: 'project/list/loading',
      templatesLoading: 'template/list/loading'
    }),
    isEditing() {
      return !!this.id
    },
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.projects')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.projects')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = this.record || {
      code: '',
      project: '',
      unitTemplate: ''
    }
    await this.doFetchProjects({
      pagination: {
        firstPage: true,
        limit: 1000,
        action: 'next',
        orderBy: 'createdAt',
        sortType: 'desc'
      }
    })
    this.projectOptions = this.projects.map((project) => ({
      ...project,
      nameAr: project.name.ar,
      nameEn: project.name.en
    }))
  },
  methods: {
    ...mapActions({
      doFetchProjects: 'project/list/doFetch',
      doFetchTemplate: 'template/list/doFetch',
      doNew: 'unit/form/doNew',
      doFind: 'unit/form/doFind',
      doCreate: 'unit/form/doAdd',
      doUpdate: 'unit/form/doUpdate'
    }),
    deleteImage(index) {
      this.model.images.splice(index, 1)
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.code.length === 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (!this.model.project?.id?.length > 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (!this.model.unitTemplate?.id?.length > 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.back()
    },
    async selectedProject(val) {
      this.selectedProjectId = val.id
      await this.doFetchTemplate({
        filter: {
          key: 'projectId',
          operator: 'equal',
          value: val.id
        },
        pagination: {
          firstPage: true,
          limit: 0,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
      this.templateOptions = this.templates.map((template) => ({
        ...template,
        nameAr: template.title.ar,
        nameEn: template.title.en
      }))
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { code, project, unitTemplate } = this.model
      await this.doCreate({
        code,
        projectId: project.id,
        unitTemplateId: unitTemplate.id
      })
    }
  }
}
</script>
<style lang="scss">
.tom-select {
  .ts-input,
  .ts-dropdown {
    width: 12rem !important;
  }
}
.dropzone {
  .dz-preview {
    .dz-details {
      font-size: 12px !important;
      padding: 0em 1em !important;
    }
    .dz-image {
      width: 80px !important;
      height: 80px !important;
    }
  }
}
.loader {
  position: absolute;
  top: -6px;
  left: 50%;
  // transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  margin: 10px auto;
  border-top: 5px solid #292929;
  border-right: 5px solid #efefef;
  border-bottom: 5px solid #efefef;
  border-left: 5px solid #efefef;
  border-radius: 100px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
